import "google-protobuf"
import proto from "../proto/msg_pb.js"
const msgUitl = {
	getMsgObj: function(e, t) {
		if (!e) {
			return null;
		}
			
		var a = this.toMsgType(e);
		if (!a) {
			return console.error("无法识别为消息类型, msgCode =   ( ".concat(a, " )")), null;
		}
		
		var n = new proto[a];
		if (n.$messageId = proto.MsgCode[e], !n.$messageId) {
			return null;
		}
			
		for (var o in t) {
			var c = "set" + o.substring(0, 1).toUpperCase() + o.substring(1);
			n[c] && n[c](t[o])
		}
		return n
	},
	toMsgType: function(e) {
		var a = "",
			n = e.split("_");
		try {
			for (let i = 0; i < n.length; i++) {
				a += n[i].substring(0, 1).toUpperCase() + n[i].substring(1).toLowerCase()
			}
		} catch (e) {
			console.log(e)
		}
		return a
	},
	toMsgTypeByCode: function(e) {
		var t = proto.MsgCode;
		for (var a in t) {
			if (e === t[a])
				return [a, this.toMsgType(a)]
		}
		return null
	},
	getMsgObjByCode: function(e, t) {
		var a = this.toMsgTypeByCode(e);
		return a ? this.getMsgObj(a[1], t) : null
	},
	toJsObj: function(e) {
		var t = {},
			a = e.__proto__;
		for (var n in a)
			if (n.startsWith("get")) {
				var o = e[n](),
					c = n.substring(3);
				t[c.substring(0, 1).toLowerCase() + c.substring(1)] = o
			}
		return t
	},
	makeMsgObj: function(e) {
		if (!e || 0 === e.length)
			return null;
		var t = (255 & e[0]) << 24 | (255 & e[1]) << 16 | (255 & e[2]) << 8 | 255 & e[3],
			a = this.toMsgTypeByCode(t),
			c = a[0],
			s = a[1];
		if (s) {
			var i = proto[s].deserializeBinary(e.subarray(4));
			return {
				msgCode: c,
				msgBody: this.toJsObj(i)
			}
		}
		return null
	}
}

export default msgUitl