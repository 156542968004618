// source: msg.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.msg.AnchorOperateUser', null, global);
goog.exportSymbol('proto.msg.MsgCode', null, global);
goog.exportSymbol('proto.msg.RedPacket', null, global);
goog.exportSymbol('proto.msg.UserBug', null, global);
goog.exportSymbol('proto.msg.UserDoCmd', null, global);
goog.exportSymbol('proto.msg.UserDoResult', null, global);
goog.exportSymbol('proto.msg.UserEntryCmd', null, global);
goog.exportSymbol('proto.msg.UserEntryResult', null, global);
goog.exportSymbol('proto.msg.UserHeartbeatCmd', null, global);
goog.exportSymbol('proto.msg.UserNewMessageResult', null, global);
goog.exportSymbol('proto.msg.UserNewMessageResult.MessageData', null, global);
goog.exportSymbol('proto.msg.UserQuitResult', null, global);
goog.exportSymbol('proto.msg.UserSendMessageCmd', null, global);
goog.exportSymbol('proto.msg.UserSendMessageResult', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msg.UserBug = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msg.UserBug, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msg.UserBug.displayName = 'proto.msg.UserBug';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msg.UserEntryCmd = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msg.UserEntryCmd, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msg.UserEntryCmd.displayName = 'proto.msg.UserEntryCmd';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msg.UserEntryResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msg.UserEntryResult.repeatedFields_, null);
};
goog.inherits(proto.msg.UserEntryResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msg.UserEntryResult.displayName = 'proto.msg.UserEntryResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msg.UserQuitResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msg.UserQuitResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msg.UserQuitResult.displayName = 'proto.msg.UserQuitResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msg.UserSendMessageCmd = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msg.UserSendMessageCmd, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msg.UserSendMessageCmd.displayName = 'proto.msg.UserSendMessageCmd';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msg.UserSendMessageResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msg.UserSendMessageResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msg.UserSendMessageResult.displayName = 'proto.msg.UserSendMessageResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msg.UserNewMessageResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.msg.UserNewMessageResult.repeatedFields_, null);
};
goog.inherits(proto.msg.UserNewMessageResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msg.UserNewMessageResult.displayName = 'proto.msg.UserNewMessageResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msg.UserNewMessageResult.MessageData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msg.UserNewMessageResult.MessageData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msg.UserNewMessageResult.MessageData.displayName = 'proto.msg.UserNewMessageResult.MessageData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msg.UserHeartbeatCmd = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msg.UserHeartbeatCmd, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msg.UserHeartbeatCmd.displayName = 'proto.msg.UserHeartbeatCmd';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msg.UserDoCmd = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msg.UserDoCmd, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msg.UserDoCmd.displayName = 'proto.msg.UserDoCmd';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msg.UserDoResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msg.UserDoResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msg.UserDoResult.displayName = 'proto.msg.UserDoResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msg.AnchorOperateUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msg.AnchorOperateUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msg.AnchorOperateUser.displayName = 'proto.msg.AnchorOperateUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.msg.RedPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.msg.RedPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.msg.RedPacket.displayName = 'proto.msg.RedPacket';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msg.UserBug.prototype.toObject = function(opt_includeInstance) {
  return proto.msg.UserBug.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msg.UserBug} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msg.UserBug.toObject = function(includeInstance, msg) {
  var f, obj = {
    bugid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msg.UserBug}
 */
proto.msg.UserBug.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msg.UserBug;
  return proto.msg.UserBug.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msg.UserBug} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msg.UserBug}
 */
proto.msg.UserBug.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBugid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msg.UserBug.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msg.UserBug.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msg.UserBug} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msg.UserBug.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBugid();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 BugId = 1;
 * @return {number}
 */
proto.msg.UserBug.prototype.getBugid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.msg.UserBug} returns this
 */
proto.msg.UserBug.prototype.setBugid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msg.UserEntryCmd.prototype.toObject = function(opt_includeInstance) {
  return proto.msg.UserEntryCmd.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msg.UserEntryCmd} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msg.UserEntryCmd.toObject = function(includeInstance, msg) {
  var f, obj = {
    appUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deviceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    matchId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msg.UserEntryCmd}
 */
proto.msg.UserEntryCmd.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msg.UserEntryCmd;
  return proto.msg.UserEntryCmd.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msg.UserEntryCmd} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msg.UserEntryCmd}
 */
proto.msg.UserEntryCmd.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msg.UserEntryCmd.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msg.UserEntryCmd.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msg.UserEntryCmd} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msg.UserEntryCmd.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMatchId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string app_user_id = 1;
 * @return {string}
 */
proto.msg.UserEntryCmd.prototype.getAppUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.UserEntryCmd} returns this
 */
proto.msg.UserEntryCmd.prototype.setAppUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string device_id = 2;
 * @return {string}
 */
proto.msg.UserEntryCmd.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.UserEntryCmd} returns this
 */
proto.msg.UserEntryCmd.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string match_id = 3;
 * @return {string}
 */
proto.msg.UserEntryCmd.prototype.getMatchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.UserEntryCmd} returns this
 */
proto.msg.UserEntryCmd.prototype.setMatchId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msg.UserEntryResult.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msg.UserEntryResult.prototype.toObject = function(opt_includeInstance) {
  return proto.msg.UserEntryResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msg.UserEntryResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msg.UserEntryResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    uid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userLevel: jspb.Message.getFieldWithDefault(msg, 4, 0),
    messageDataListListList: jspb.Message.toObjectList(msg.getMessageDataListListList(),
    proto.msg.UserNewMessageResult.MessageData.toObject, includeInstance),
    usericon: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msg.UserEntryResult}
 */
proto.msg.UserEntryResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msg.UserEntryResult;
  return proto.msg.UserEntryResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msg.UserEntryResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msg.UserEntryResult}
 */
proto.msg.UserEntryResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserLevel(value);
      break;
    case 5:
      var value = new proto.msg.UserNewMessageResult.MessageData;
      reader.readMessage(value,proto.msg.UserNewMessageResult.MessageData.deserializeBinaryFromReader);
      msg.addMessageDataListList(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsericon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msg.UserEntryResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msg.UserEntryResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msg.UserEntryResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msg.UserEntryResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getUid();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserLevel();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getMessageDataListListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.msg.UserNewMessageResult.MessageData.serializeBinaryToWriter
    );
  }
  f = message.getUsericon();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional uint32 Code = 1;
 * @return {number}
 */
proto.msg.UserEntryResult.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.msg.UserEntryResult} returns this
 */
proto.msg.UserEntryResult.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 Uid = 2;
 * @return {number}
 */
proto.msg.UserEntryResult.prototype.getUid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.msg.UserEntryResult} returns this
 */
proto.msg.UserEntryResult.prototype.setUid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string user_name = 3;
 * @return {string}
 */
proto.msg.UserEntryResult.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.UserEntryResult} returns this
 */
proto.msg.UserEntryResult.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 user_level = 4;
 * @return {number}
 */
proto.msg.UserEntryResult.prototype.getUserLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.msg.UserEntryResult} returns this
 */
proto.msg.UserEntryResult.prototype.setUserLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated UserNewMessageResult.MessageData message_data_list_list = 5;
 * @return {!Array<!proto.msg.UserNewMessageResult.MessageData>}
 */
proto.msg.UserEntryResult.prototype.getMessageDataListListList = function() {
  return /** @type{!Array<!proto.msg.UserNewMessageResult.MessageData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.msg.UserNewMessageResult.MessageData, 5));
};


/**
 * @param {!Array<!proto.msg.UserNewMessageResult.MessageData>} value
 * @return {!proto.msg.UserEntryResult} returns this
*/
proto.msg.UserEntryResult.prototype.setMessageDataListListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.msg.UserNewMessageResult.MessageData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msg.UserNewMessageResult.MessageData}
 */
proto.msg.UserEntryResult.prototype.addMessageDataListList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.msg.UserNewMessageResult.MessageData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msg.UserEntryResult} returns this
 */
proto.msg.UserEntryResult.prototype.clearMessageDataListListList = function() {
  return this.setMessageDataListListList([]);
};


/**
 * optional string UserIcon = 6;
 * @return {string}
 */
proto.msg.UserEntryResult.prototype.getUsericon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.UserEntryResult} returns this
 */
proto.msg.UserEntryResult.prototype.setUsericon = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msg.UserQuitResult.prototype.toObject = function(opt_includeInstance) {
  return proto.msg.UserQuitResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msg.UserQuitResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msg.UserQuitResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msg.UserQuitResult}
 */
proto.msg.UserQuitResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msg.UserQuitResult;
  return proto.msg.UserQuitResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msg.UserQuitResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msg.UserQuitResult}
 */
proto.msg.UserQuitResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msg.UserQuitResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msg.UserQuitResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msg.UserQuitResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msg.UserQuitResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 uid = 1;
 * @return {number}
 */
proto.msg.UserQuitResult.prototype.getUid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.msg.UserQuitResult} returns this
 */
proto.msg.UserQuitResult.prototype.setUid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msg.UserSendMessageCmd.prototype.toObject = function(opt_includeInstance) {
  return proto.msg.UserSendMessageCmd.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msg.UserSendMessageCmd} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msg.UserSendMessageCmd.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    msgId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    languageType: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msg.UserSendMessageCmd}
 */
proto.msg.UserSendMessageCmd.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msg.UserSendMessageCmd;
  return proto.msg.UserSendMessageCmd.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msg.UserSendMessageCmd} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msg.UserSendMessageCmd}
 */
proto.msg.UserSendMessageCmd.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMsgId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguageType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msg.UserSendMessageCmd.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msg.UserSendMessageCmd.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msg.UserSendMessageCmd} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msg.UserSendMessageCmd.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMsgId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getLanguageType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.msg.UserSendMessageCmd.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.UserSendMessageCmd} returns this
 */
proto.msg.UserSendMessageCmd.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 msg_id = 2;
 * @return {number}
 */
proto.msg.UserSendMessageCmd.prototype.getMsgId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.msg.UserSendMessageCmd} returns this
 */
proto.msg.UserSendMessageCmd.prototype.setMsgId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string language_type = 3;
 * @return {string}
 */
proto.msg.UserSendMessageCmd.prototype.getLanguageType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.UserSendMessageCmd} returns this
 */
proto.msg.UserSendMessageCmd.prototype.setLanguageType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msg.UserSendMessageResult.prototype.toObject = function(opt_includeInstance) {
  return proto.msg.UserSendMessageResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msg.UserSendMessageResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msg.UserSendMessageResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    msgId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    recoverTime: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msg.UserSendMessageResult}
 */
proto.msg.UserSendMessageResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msg.UserSendMessageResult;
  return proto.msg.UserSendMessageResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msg.UserSendMessageResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msg.UserSendMessageResult}
 */
proto.msg.UserSendMessageResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMsgId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRecoverTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msg.UserSendMessageResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msg.UserSendMessageResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msg.UserSendMessageResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msg.UserSendMessageResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getMsgId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getRecoverTime();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 code = 1;
 * @return {number}
 */
proto.msg.UserSendMessageResult.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.msg.UserSendMessageResult} returns this
 */
proto.msg.UserSendMessageResult.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 msg_id = 2;
 * @return {number}
 */
proto.msg.UserSendMessageResult.prototype.getMsgId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.msg.UserSendMessageResult} returns this
 */
proto.msg.UserSendMessageResult.prototype.setMsgId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 recover_time = 3;
 * @return {number}
 */
proto.msg.UserSendMessageResult.prototype.getRecoverTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.msg.UserSendMessageResult} returns this
 */
proto.msg.UserSendMessageResult.prototype.setRecoverTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.msg.UserNewMessageResult.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msg.UserNewMessageResult.prototype.toObject = function(opt_includeInstance) {
  return proto.msg.UserNewMessageResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msg.UserNewMessageResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msg.UserNewMessageResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    messagedatalistlistList: jspb.Message.toObjectList(msg.getMessagedatalistlistList(),
    proto.msg.UserNewMessageResult.MessageData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msg.UserNewMessageResult}
 */
proto.msg.UserNewMessageResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msg.UserNewMessageResult;
  return proto.msg.UserNewMessageResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msg.UserNewMessageResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msg.UserNewMessageResult}
 */
proto.msg.UserNewMessageResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.msg.UserNewMessageResult.MessageData;
      reader.readMessage(value,proto.msg.UserNewMessageResult.MessageData.deserializeBinaryFromReader);
      msg.addMessagedatalistlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msg.UserNewMessageResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msg.UserNewMessageResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msg.UserNewMessageResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msg.UserNewMessageResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessagedatalistlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.msg.UserNewMessageResult.MessageData.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msg.UserNewMessageResult.MessageData.prototype.toObject = function(opt_includeInstance) {
  return proto.msg.UserNewMessageResult.MessageData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msg.UserNewMessageResult.MessageData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msg.UserNewMessageResult.MessageData.toObject = function(includeInstance, msg) {
  var f, obj = {
    msgId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userIcon: jspb.Message.getFieldWithDefault(msg, 2, ""),
    uid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    userName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    userLevel: jspb.Message.getFieldWithDefault(msg, 5, 0),
    msgType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    eb: jspb.Message.getFieldWithDefault(msg, 7, ""),
    id: jspb.Message.getFieldWithDefault(msg, 8, ""),
    spa: jspb.Message.getFieldWithDefault(msg, 9, ""),
    fra: jspb.Message.getFieldWithDefault(msg, 10, ""),
    pt: jspb.Message.getFieldWithDefault(msg, 11, ""),
    vie: jspb.Message.getFieldWithDefault(msg, 12, ""),
    tr: jspb.Message.getFieldWithDefault(msg, 13, ""),
    ru: jspb.Message.getFieldWithDefault(msg, 14, ""),
    th: jspb.Message.getFieldWithDefault(msg, 15, ""),
    bur: jspb.Message.getFieldWithDefault(msg, 16, ""),
    zh: jspb.Message.getFieldWithDefault(msg, 17, ""),
    cht: jspb.Message.getFieldWithDefault(msg, 18, ""),
    text: jspb.Message.getFieldWithDefault(msg, 19, ""),
    languageType: jspb.Message.getFieldWithDefault(msg, 20, ""),
    liveMsgType: jspb.Message.getFieldWithDefault(msg, 21, 0),
    userType: jspb.Message.getFieldWithDefault(msg, 22, 0),
    appNumber: jspb.Message.getFieldWithDefault(msg, 23, ""),
    operateName: jspb.Message.getFieldWithDefault(msg, 24, ""),
    blockName: jspb.Message.getFieldWithDefault(msg, 25, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msg.UserNewMessageResult.MessageData}
 */
proto.msg.UserNewMessageResult.MessageData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msg.UserNewMessageResult.MessageData;
  return proto.msg.UserNewMessageResult.MessageData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msg.UserNewMessageResult.MessageData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msg.UserNewMessageResult.MessageData}
 */
proto.msg.UserNewMessageResult.MessageData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMsgId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserIcon(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserLevel(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMsgType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEb(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpa(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setFra(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPt(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setVie(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTr(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setRu(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setTh(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setBur(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setZh(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCht(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguageType(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLiveMsgType(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserType(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppNumber(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperateName(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlockName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msg.UserNewMessageResult.MessageData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msg.UserNewMessageResult.MessageData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msg.UserNewMessageResult.MessageData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msg.UserNewMessageResult.MessageData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMsgId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getUserIcon();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUid();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUserLevel();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getMsgType();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getEb();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSpa();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getFra();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPt();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getVie();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTr();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getRu();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getTh();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getBur();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getZh();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getCht();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getLanguageType();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getLiveMsgType();
  if (f !== 0) {
    writer.writeUint32(
      21,
      f
    );
  }
  f = message.getUserType();
  if (f !== 0) {
    writer.writeUint32(
      22,
      f
    );
  }
  f = message.getAppNumber();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getOperateName();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getBlockName();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
};


/**
 * optional uint32 msg_id = 1;
 * @return {number}
 */
proto.msg.UserNewMessageResult.MessageData.prototype.getMsgId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.msg.UserNewMessageResult.MessageData} returns this
 */
proto.msg.UserNewMessageResult.MessageData.prototype.setMsgId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string user_icon = 2;
 * @return {string}
 */
proto.msg.UserNewMessageResult.MessageData.prototype.getUserIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.UserNewMessageResult.MessageData} returns this
 */
proto.msg.UserNewMessageResult.MessageData.prototype.setUserIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 uid = 3;
 * @return {number}
 */
proto.msg.UserNewMessageResult.MessageData.prototype.getUid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.msg.UserNewMessageResult.MessageData} returns this
 */
proto.msg.UserNewMessageResult.MessageData.prototype.setUid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string user_name = 4;
 * @return {string}
 */
proto.msg.UserNewMessageResult.MessageData.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.UserNewMessageResult.MessageData} returns this
 */
proto.msg.UserNewMessageResult.MessageData.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 user_level = 5;
 * @return {number}
 */
proto.msg.UserNewMessageResult.MessageData.prototype.getUserLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.msg.UserNewMessageResult.MessageData} returns this
 */
proto.msg.UserNewMessageResult.MessageData.prototype.setUserLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 msg_type = 6;
 * @return {number}
 */
proto.msg.UserNewMessageResult.MessageData.prototype.getMsgType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.msg.UserNewMessageResult.MessageData} returns this
 */
proto.msg.UserNewMessageResult.MessageData.prototype.setMsgType = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string eb = 7;
 * @return {string}
 */
proto.msg.UserNewMessageResult.MessageData.prototype.getEb = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.UserNewMessageResult.MessageData} returns this
 */
proto.msg.UserNewMessageResult.MessageData.prototype.setEb = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string id = 8;
 * @return {string}
 */
proto.msg.UserNewMessageResult.MessageData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.UserNewMessageResult.MessageData} returns this
 */
proto.msg.UserNewMessageResult.MessageData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string spa = 9;
 * @return {string}
 */
proto.msg.UserNewMessageResult.MessageData.prototype.getSpa = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.UserNewMessageResult.MessageData} returns this
 */
proto.msg.UserNewMessageResult.MessageData.prototype.setSpa = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string fra = 10;
 * @return {string}
 */
proto.msg.UserNewMessageResult.MessageData.prototype.getFra = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.UserNewMessageResult.MessageData} returns this
 */
proto.msg.UserNewMessageResult.MessageData.prototype.setFra = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string pt = 11;
 * @return {string}
 */
proto.msg.UserNewMessageResult.MessageData.prototype.getPt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.UserNewMessageResult.MessageData} returns this
 */
proto.msg.UserNewMessageResult.MessageData.prototype.setPt = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string vie = 12;
 * @return {string}
 */
proto.msg.UserNewMessageResult.MessageData.prototype.getVie = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.UserNewMessageResult.MessageData} returns this
 */
proto.msg.UserNewMessageResult.MessageData.prototype.setVie = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string tr = 13;
 * @return {string}
 */
proto.msg.UserNewMessageResult.MessageData.prototype.getTr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.UserNewMessageResult.MessageData} returns this
 */
proto.msg.UserNewMessageResult.MessageData.prototype.setTr = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string ru = 14;
 * @return {string}
 */
proto.msg.UserNewMessageResult.MessageData.prototype.getRu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.UserNewMessageResult.MessageData} returns this
 */
proto.msg.UserNewMessageResult.MessageData.prototype.setRu = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string th = 15;
 * @return {string}
 */
proto.msg.UserNewMessageResult.MessageData.prototype.getTh = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.UserNewMessageResult.MessageData} returns this
 */
proto.msg.UserNewMessageResult.MessageData.prototype.setTh = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string bur = 16;
 * @return {string}
 */
proto.msg.UserNewMessageResult.MessageData.prototype.getBur = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.UserNewMessageResult.MessageData} returns this
 */
proto.msg.UserNewMessageResult.MessageData.prototype.setBur = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string zh = 17;
 * @return {string}
 */
proto.msg.UserNewMessageResult.MessageData.prototype.getZh = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.UserNewMessageResult.MessageData} returns this
 */
proto.msg.UserNewMessageResult.MessageData.prototype.setZh = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string cht = 18;
 * @return {string}
 */
proto.msg.UserNewMessageResult.MessageData.prototype.getCht = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.UserNewMessageResult.MessageData} returns this
 */
proto.msg.UserNewMessageResult.MessageData.prototype.setCht = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string text = 19;
 * @return {string}
 */
proto.msg.UserNewMessageResult.MessageData.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.UserNewMessageResult.MessageData} returns this
 */
proto.msg.UserNewMessageResult.MessageData.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string language_type = 20;
 * @return {string}
 */
proto.msg.UserNewMessageResult.MessageData.prototype.getLanguageType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.UserNewMessageResult.MessageData} returns this
 */
proto.msg.UserNewMessageResult.MessageData.prototype.setLanguageType = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional uint32 live_msg_type = 21;
 * @return {number}
 */
proto.msg.UserNewMessageResult.MessageData.prototype.getLiveMsgType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.msg.UserNewMessageResult.MessageData} returns this
 */
proto.msg.UserNewMessageResult.MessageData.prototype.setLiveMsgType = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional uint32 user_type = 22;
 * @return {number}
 */
proto.msg.UserNewMessageResult.MessageData.prototype.getUserType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.msg.UserNewMessageResult.MessageData} returns this
 */
proto.msg.UserNewMessageResult.MessageData.prototype.setUserType = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional string app_number = 23;
 * @return {string}
 */
proto.msg.UserNewMessageResult.MessageData.prototype.getAppNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.UserNewMessageResult.MessageData} returns this
 */
proto.msg.UserNewMessageResult.MessageData.prototype.setAppNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string operate_name = 24;
 * @return {string}
 */
proto.msg.UserNewMessageResult.MessageData.prototype.getOperateName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.UserNewMessageResult.MessageData} returns this
 */
proto.msg.UserNewMessageResult.MessageData.prototype.setOperateName = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string block_name = 25;
 * @return {string}
 */
proto.msg.UserNewMessageResult.MessageData.prototype.getBlockName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.UserNewMessageResult.MessageData} returns this
 */
proto.msg.UserNewMessageResult.MessageData.prototype.setBlockName = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * repeated MessageData MessageDataListList = 1;
 * @return {!Array<!proto.msg.UserNewMessageResult.MessageData>}
 */
proto.msg.UserNewMessageResult.prototype.getMessagedatalistlistList = function() {
  return /** @type{!Array<!proto.msg.UserNewMessageResult.MessageData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.msg.UserNewMessageResult.MessageData, 1));
};


/**
 * @param {!Array<!proto.msg.UserNewMessageResult.MessageData>} value
 * @return {!proto.msg.UserNewMessageResult} returns this
*/
proto.msg.UserNewMessageResult.prototype.setMessagedatalistlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.msg.UserNewMessageResult.MessageData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.msg.UserNewMessageResult.MessageData}
 */
proto.msg.UserNewMessageResult.prototype.addMessagedatalistlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.msg.UserNewMessageResult.MessageData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.msg.UserNewMessageResult} returns this
 */
proto.msg.UserNewMessageResult.prototype.clearMessagedatalistlistList = function() {
  return this.setMessagedatalistlistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msg.UserHeartbeatCmd.prototype.toObject = function(opt_includeInstance) {
  return proto.msg.UserHeartbeatCmd.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msg.UserHeartbeatCmd} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msg.UserHeartbeatCmd.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msg.UserHeartbeatCmd}
 */
proto.msg.UserHeartbeatCmd.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msg.UserHeartbeatCmd;
  return proto.msg.UserHeartbeatCmd.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msg.UserHeartbeatCmd} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msg.UserHeartbeatCmd}
 */
proto.msg.UserHeartbeatCmd.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msg.UserHeartbeatCmd.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msg.UserHeartbeatCmd.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msg.UserHeartbeatCmd} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msg.UserHeartbeatCmd.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msg.UserDoCmd.prototype.toObject = function(opt_includeInstance) {
  return proto.msg.UserDoCmd.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msg.UserDoCmd} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msg.UserDoCmd.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msg.UserDoCmd}
 */
proto.msg.UserDoCmd.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msg.UserDoCmd;
  return proto.msg.UserDoCmd.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msg.UserDoCmd} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msg.UserDoCmd}
 */
proto.msg.UserDoCmd.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msg.UserDoCmd.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msg.UserDoCmd.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msg.UserDoCmd} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msg.UserDoCmd.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 code = 1;
 * @return {number}
 */
proto.msg.UserDoCmd.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.msg.UserDoCmd} returns this
 */
proto.msg.UserDoCmd.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.msg.UserDoCmd.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.UserDoCmd} returns this
 */
proto.msg.UserDoCmd.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 type = 3;
 * @return {number}
 */
proto.msg.UserDoCmd.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.msg.UserDoCmd} returns this
 */
proto.msg.UserDoCmd.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msg.UserDoResult.prototype.toObject = function(opt_includeInstance) {
  return proto.msg.UserDoResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msg.UserDoResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msg.UserDoResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msg.UserDoResult}
 */
proto.msg.UserDoResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msg.UserDoResult;
  return proto.msg.UserDoResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msg.UserDoResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msg.UserDoResult}
 */
proto.msg.UserDoResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msg.UserDoResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msg.UserDoResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msg.UserDoResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msg.UserDoResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 code = 1;
 * @return {number}
 */
proto.msg.UserDoResult.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.msg.UserDoResult} returns this
 */
proto.msg.UserDoResult.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.msg.UserDoResult.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.UserDoResult} returns this
 */
proto.msg.UserDoResult.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 type = 3;
 * @return {number}
 */
proto.msg.UserDoResult.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.msg.UserDoResult} returns this
 */
proto.msg.UserDoResult.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msg.AnchorOperateUser.prototype.toObject = function(opt_includeInstance) {
  return proto.msg.AnchorOperateUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msg.AnchorOperateUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msg.AnchorOperateUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    operateType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actionType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msg.AnchorOperateUser}
 */
proto.msg.AnchorOperateUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msg.AnchorOperateUser;
  return proto.msg.AnchorOperateUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msg.AnchorOperateUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msg.AnchorOperateUser}
 */
proto.msg.AnchorOperateUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOperateType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setActionType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msg.AnchorOperateUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msg.AnchorOperateUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msg.AnchorOperateUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msg.AnchorOperateUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperateType();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getUserType();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getActionType();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 operate_type = 1;
 * @return {number}
 */
proto.msg.AnchorOperateUser.prototype.getOperateType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.msg.AnchorOperateUser} returns this
 */
proto.msg.AnchorOperateUser.prototype.setOperateType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 user_type = 2;
 * @return {number}
 */
proto.msg.AnchorOperateUser.prototype.getUserType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.msg.AnchorOperateUser} returns this
 */
proto.msg.AnchorOperateUser.prototype.setUserType = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 action_type = 3;
 * @return {number}
 */
proto.msg.AnchorOperateUser.prototype.getActionType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.msg.AnchorOperateUser} returns this
 */
proto.msg.AnchorOperateUser.prototype.setActionType = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.msg.RedPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.msg.RedPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.msg.RedPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msg.RedPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.msg.RedPacket}
 */
proto.msg.RedPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.msg.RedPacket;
  return proto.msg.RedPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.msg.RedPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.msg.RedPacket}
 */
proto.msg.RedPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.msg.RedPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.msg.RedPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.msg.RedPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.msg.RedPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.msg.RedPacket.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.RedPacket} returns this
 */
proto.msg.RedPacket.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.msg.RedPacket.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.msg.RedPacket} returns this
 */
proto.msg.RedPacket.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.msg.MsgCode = {
  USER_BUG: 0,
  USER_ENTRY_CMD: 1,
  USER_ENTRY_RESULT: 2,
  USER_QUIT_RESULT: 3,
  USER_SEND_MESSAGE_CMD: 4,
  USER_SEND_MESSAGE_RESULT: 5,
  USER_NEW_MESSAGE_RESULT: 6,
  USER_HEARTBEAT_CMD: 7,
  USER_DO_CMD: 8,
  USER_DO_RESULT: 9,
  ANCHOR_OPERATE_USER: 10,
  RED_PACKET: 11
};

goog.object.extend(exports, proto.msg);
