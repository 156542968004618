const utils = {
	test(log) {
		console.log(log)
	},
	ballsTypeEnum: {
		football: 1,
		basketball: 2,
		tennis: 3,
		ice_hockey: 8,
		volleyball: 10
	}
}
export default utils;