const gameUtil = {
	timer: null,
	ballsTypeEnum: {
		football: 1,
		basketball: 2,
		tennis: 3,
		ice_hockey: 8,
		volleyball: 10
	},

	calcMatchTime: function(t) {
		t.sportId === gameUtil.ballsTypeEnum.football && gameUtil.combineFootballGameData(t),
			t.sportId === gameUtil.ballsTypeEnum.basketball && gameUtil.combineBasketballGameData(t),
			t.sportId === gameUtil.ballsTypeEnum.tennis && gameUtil.combineTennisGameData(t),
			t.sportId === gameUtil.ballsTypeEnum.volleyball && gameUtil.combineVolleyballGameData(t),
			3 !== t.matchStatus && 5 !== t.matchStatus || clearInterval(t.timer)
	},
	combineFootballGameData: function(t) {
		var e = Date.parse(new Date) / 1e3,
			a = 0;
		t.times && t.times.tmrUpdated && (a = (a = e - t.times.tmrUpdated) < 0 ? 0 : a,
				4 === t.statusId && (a += t.times.tmrSecs)),
			this.calcFootballGameScore(t),
			this.calcFootballGameMins(t)

		t.diffSeconds = a
	},
	calcFootballGameScore: function(t) {
		var e = 0,
			a = 0;
		t.homeScores && t.awayScores && (t.homeScores[6] || t.awayScores[6] ? (e = t.homeScores[6],
				a = t.awayScores[6]) : (e = t.homeScores[5] > 0 ? t.homeScores[5] : t.homeScores[0],
				a = t.awayScores[5] > 0 ? t.awayScores[5] : t.awayScores[0])),
			(t.homeTotalScore = t.homeScores[5] ? t.homeScores[5] : t.homeScores[0]),
			(t.awayTotalScore = t.awayScores[5] ? t.awayScores[5] : t.awayScores[0]),
			(t.winTeamScoreDiff = e - a)

		// this.$set(t, "homeTotalScore", t.homeScores[5] ? t.homeScores[5] : t.homeScores[0]),
		// this.$set(t, "awayTotalScore", t.awayScores[5] ? t.awayScores[5] : t.awayScores[0]),
		// this.$set(t, "winTeamScoreDiff", e - a)
	},
	calcFootballGameMins: function(t) {
		var e = t.diffSeconds ? Math.floor(t.diffSeconds / 60) + 1 : 0,
			a = e;
		2 === t.statusId && e > 45 && (a = "".concat(45, "+").concat(e - 45)),
			4 === t.statusId && e > 90 && (a = "".concat(90, "+").concat(e - 90))
		t.diffMins = e
		t.diffMinsText = a ? a : "-"

		// if(t.statusId === 2 && e > 45) {
		// 	a = '45+'.concat(e - 45)
		// } else if(t.statusId === 4 && e > 90) {
		// 	a = '90+'.concat(e - 90)
		// }
		// t.diffMins = e
		// t.diffMinsText = a
		// 2 === t.statusId && e > 45 && (a = "".concat(45, "+").concat(e - 45)),
		// 	4 === t.statusId && e > 90 && (a = "".concat(90, "+").concat(e - 90)),
		// 	(t.diffMins = e), (t.diffMinsText = a)
		// this.$set(t, "diffMins", e),
		// this.$set(t, "diffMinsText", a)
	},

	combineBasketballGameData: function(t) {
		this.calcBasketballGameScore(t),
			this.calcBasketballGameMins(t)
	},
	sumBasketballScores: function(t) {
		return t && t.length ? t.reduce((function(t, e, a) {
			return a >= 5 ? t : t + e
		}), 0) : 0
	},
	calcBasketballGameScore: function(t) {
		var e = this.sumBasketballScores(t.homeScores || []),
			a = this.sumBasketballScores(t.awayScores || []);

		t.homeTotalScore = e
		t.awayTotalScore = a
		t.winTeamScoreDiff = e - a
		// this.$set(t, "homeTotalScore", e),
		// 	this.$set(t, "awayTotalScore", a),
		// 	this.$set(t, "winTeamScoreDiff", e - a)
	},
	calcBasketballGameMins: function(t) {
		t.times.tmrSecs = t.times.tmrSecs < 0 ? 0 : t.times.tmrSecs;
		var e = t.times.tmrSecs ? Math.floor(t.times.tmrSecs / 60) : 0;
		e = e >= 10 ? e : "0".concat(e);
		var a = t.times.tmrSecs ? t.times.tmrSecs % 60 : 0;
		a = a >= 10 ? a : "0".concat(a);
		var s = "".concat(e, ":").concat(a);
		if (t.statusId === 1) {
			s = '第一节 10:00'
		} else if (t.statusId === 2) {
			s = '第一节 '.concat(s)
		} else if (t.statusId === 3) {
			s = '第一节-结束'
		} else if (t.statusId === 4) {
			s = '第二节 '.concat(s)
		} else if (t.statusId === 5) {
			s = '第二节-结束'
		} else if (t.statusId === 6) {
			s = '第三节 '.concat(s)
		} else if (t.statusId === 7) {
			s = '第三节-结束'
		} else if (t.statusId === 8) {
			s = '第四节 '.concat(s)
		} else if (t.statusId === 9 && (Object.prototype.hasOwnProperty.call(t, "isOT"))) {
			s = t.isOT ? "OT-".concat(s) : "".concat(this.isZhLang() ? "第四节-结束" : "Q4-Ended")
		} else {
			t.isOT = !!t.times.tmrSecs
		}
		t.diffMinsText = s
		// 1 === t.statusId ? s = "".concat(this.isZhLang() ? "第一节" : "Q1", " 10:00") : 2 === t.statusId ? s = ""
		// 	.concat(this.isZhLang() ? "第一节" : "Q1", " ").concat(s) : 3 === t.statusId ? s = "".concat(this
		// 		.isZhLang() ? "第一节-结束" : "Q1-Ended") : 4 === t.statusId ? s = "".concat(this.isZhLang() ?
		// 		"第二节" : "Q2", " ").concat(s) : 5 === t.statusId ? s = "".concat(this.isZhLang() ? "第二节-结束" :
		// 		"Q2-Ended") : 6 === t.statusId ? s = "".concat(this.isZhLang() ? "第三节" : "Q3", " ").concat(s) :
		// 	7 === t.statusId ? s = "".concat(this.isZhLang() ? "第三节-结束" : "Q3-Ended") : 8 === t.statusId ? s =
		// 	"".concat(this.isZhLang() ? "第四节" : "Q4", " ").concat(s) : 9 === t.statusId && (Object.prototype
		// 		.hasOwnProperty.call(t, "isOT") ? s = t.isOT ? "OT-".concat(s) : "".concat(this.isZhLang() ?
		// 			"第四节-结束" : "Q4-Ended") : (t.isOT = !!t.times.tmrSecs, t.diffMinsText = s)

		// 			this.$set(t, "isOT", !!t.times.tmrSecs)),
		// this.$set(t, "diffMinsText", s)
	},
	isZhLang() {
		return true;
	},

	combineTennisGameData: function(t) {
		this.getTennisScoreCount(t)
		this.calcTennisGameMins(t)
	},
	$set: function(t, k, v) {
		t.k = v
		console.log(k, v)
	},
	getTennisScoreCount: function(t) {
		var e = t.statusId - 2,
			a = 0,
			s = 0,
			c = 0,
			o = 0;
		if (3 === t.matchStatus) {
			for (var i = t.homeScores.length, n = t.awayScores.length, r = Math.max(i, n), l = 0; l <
				r; l++)
				if (l % 2 == 0) {
					var h = t.homeScores[l],
						m = t.awayScores[l];
					h > m && (c += 1),
						m > h && (o += 1)
				}
			t.winTeamScoreDiff = c - o;
			t.homeTotalScore = c;
			t.awayTotalScore = o;
		}
		if ((2 === t.matchStatus || 5 === t.matchStatus) && e >= 0 && e % 2 == 0) {
			c = 0,
				o = 0,
				a = t.homeScores[e] || 0,
				s = t.awayScores[e] || 0;
			for (var S = 0; S < e; S++)
				if (S % 2 == 0) {
					var u = t.homeScores[S],
						b = t.awayScores[S];
					u > b && (c += 1),
						b > u && (o += 1)
				}
			t.winTeamScoreDiff = c - o;
			t.homeTotalScore = c;
			t.awayTotalScore = o;
			t.homeStartedMatchScore = a;
			t.awayStartedMatchScore = s;
			// this.$set(t, "winTeamScoreDiff", c - o),
			// 	this.$set(t, "homeTotalScore", c),
			// 	this.$set(t, "awayTotalScore", o),
			// 	this.$set(t, "homeStartedMatchScore", a),
			// 	this.$set(t, "awayStartedMatchScore", s)
		}
	},
	calcTennisGameMins: function(t) {
		var e = "";
		2 === t.statusId ? e = "Set 1" : 4 === t.statusId ? e = "Set 2" : 6 === t.statusId ? e =
			"Set 3" : 8 ===
			t.statusId ? e = "Set 4" : 10 === t.statusId && (e = "Set 5"),
			t.diffMinsText = e
		// this.$set(t, "diffMinsText", e)
	},
	combineVolleyballGameData: function(t) {
		this.getVolleyballScoreCount(t),
			this.calcVolleyballGameMins(t),
			this.sunTeamScore(t)
	},
	sunTeamScore: function(t) {
		for (var e = 0, a = 0, s = t.vbScores || {}, c = 1; c <= t.totalSetNum; c++) {
			s["p".concat(c)] && (e += s["p".concat(c)][0],
				a += s["p".concat(c)][1]);
		}
		s.homeScores = e;
		s.awayScores = a;
		// this.$set(s, "homeScores", e),
		// this.$set(s, "awayScores", a)
	},
	getVolleyballScoreCount: function(t) {
		for (var e, a = t.vbScores || {}, s = a.pt || [0, 0], c = 1, o = 1; o <= t.totalSetNum; o++)
			a["p".concat(o)] && (e = a["p".concat(o)],
				c = o);
		2 !== t.matchStatus && 5 !== t.matchStatus || this.$set(a, "currentSetScore", e || [0, 0]);
		var i = s[0],
			n = s[1];

		t.homeTotalScore = i;
		t.awayTotalScore = n
		t.winTeamScoreDiff = i - n
		t.totalSet = c
		// this.$set(t, "homeTotalScore", i),
		// 	this.$set(t, "awayTotalScore", n),
		// 	this.$set(t, "winTeamScoreDiff", i - n),
		// 	this.$set(a, "totalSet", c)
	},
	calcVolleyballGameMins: function(t) {
		var e = "";
		switch (t.statusId) {
			case 432:
				e = "Set 1";
				break;
			case 434:
				e = "Set 2";
				break;
			case 436:
				e = "Set 3";
				break;
			case 438:
				e = "Set 4";
				break;
			case 440:
				e = "Set 5"
		}
		t.diffMinsText = e
		// this.$set(t, "diffMinsText", e)
	}

}

export default gameUtil